<template>
  <div>
    <div class="upper">
      <el-row :gutter="15">
        <el-col :span="16">
          <div class="upper-l">
            <div class="summary flex-j-b">
              <div class="item flex-al cur" @click="$router.push('/contract')">
                <img src="../assets/images/sign.png">
                <div>
                  <h3 class="t-c">{{statdata.signatureNum||0}}</h3>
                  <p class="f14">签字</p>
                </div>
              </div>
              <div class="item flex-al cur" @click="$router.push('/monitoring')">
                <img src="../assets/images/fee.png">
                <div>
                  <h3 class="t-c">{{statdata.annualFeeNum||0}}</h3>
                  <p class="f14">我的年费</p>
                </div>
              </div>
              <div class="item flex-al cur" @click="$router.push('/order')">
                <img src="../assets/images/dz.png">
                <div>
                  <h3 class="t-c">{{statdata.ordersnNum||0}}</h3>
                  <p class="f14">待支付</p>
                </div>
              </div>
              <div class="item flex-al cur" @click="$router.push('/coupon')">
                <img src="../assets/images/coupon.png">
                <div>
                  <h3 class="t-c">{{statdata.memberCouponNum||0}}</h3>
                  <p class="f14">优惠券</p>
                </div>
              </div>
            </div>
            <business></business>
          </div>
        </el-col>
        <el-col :span="8">
          <monitor></monitor>
        </el-col>
      </el-row>
    </div>
    <div class="lower mt-20">
      <el-row :gutter="15">
        <el-col :span="16">
          <div class="lower-l">
            <div class="title flex">
              <span>年费分析</span>
              <!-- <div class="lower-l-btn cur">
                <i class="el-icon-arrow-up"></i>
                <span>导出数据</span>
              </div>-->
            </div>
            <div class="content1 flex-j-b">
              <div class="company">
                <div v-if="annualFeedata.client_type==1||annualFeedata.client_type==3" class="title2">{{annualFeedata.username}}</div>
                <div
                  v-if="annualFeedata.client_type==1||annualFeedata.client_type==3"
                  class="company-item mt-30 flex"
                >
                  <div>
                    <p>30项0元</p>
                    <p>当前可申报</p>
                  </div>
                  <div>
                    <p>30项0元</p>
                    <p>即将可申报</p>
                  </div>
                  <div>
                    <p>30项</p>
                    <p>政策规划</p>
                  </div>
                  <div>
                    <p>30项</p>
                    <p>历史资助</p>
                  </div>
                </div>
                <div class="banner" v-else></div>
              </div>
              <div class="patent">
                <div class="title2">我的专利</div>
                <div class="patent-item flex-j-b mt-30">
                  <div class="item-img flex">
                    <img class="mr-10" src="../assets/images/mypatent.png">
                    <div>
                      <p>{{annualFeedata.patentNum}}项</p>
                      <p>我的专利</p>
                    </div>
                  </div>
                  <div class="item-img flex">
                    <img class="mr-10" src="../assets/images/transfer.png">
                    <div>
                      <p>{{annualFeedata.patentNum}}项</p>
                      <p>可转让专利</p>
                    </div>
                  </div>
                  <div class="item-p" >
                    <p @click="$router.push('/import')">添加专利</p>
                    <p @click="$router.push('/trade')">转让专利</p>
                  </div>
                </div>
              </div>
            </div>
            <div class="content2 flex-j-b">
              <div class="compare">
                <div class="title2">年度优惠对比</div>
                <div class="compare-item flex-j-b">
                  <div>
                    <div style="width: 80px; height: 80px" class="compare-item1"></div>
                    <div class="mt-10 f14">
                      全年优惠
                      <span>8%</span>
                    </div>
                  </div>
                  <div>
                    <div style="width: 90px; height: 90px" class="compare-item2"></div>
                    <div class="mt-10 f14">
                      全年优惠
                      <span>15%</span>
                    </div>
                  </div>
                  <div>
                    <div style="width: 100px; height: 100px" class="compare-item3"></div>
                    <div class="mt-10 f14">
                      全年优惠
                      <span>30%</span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="sum">
                <div class="title2">充值中心</div>
                <div class="sum-item flex-j-b">
                  <div class="coupon" @click="goRecharge()">
                    <div class="f11 coupon-top">
                      <span class="p7">送10元</span>
                    </div>
                    <div class="f20 p7 save-all">¥500</div>
                    <div class="f11 p7">充值500元赠送10元</div>
                  </div>
                  <div class="coupon" @click="goRecharge()">
                    <div class="f11 coupon-top">
                      <span class="p7">送50元</span>
                    </div>
                    <div class="f20 p7 save-all">¥1000</div>
                    <div class="f11 p7">充值1000元赠送50元</div>
                  </div>
                  <div class="coupon no" @click="goRecharge()">
                    <div class="f11 coupon-top">
                      <span class="p7">送100元</span>
                    </div>
                    <div class="f20 p7 save-all">¥2000</div>
                    <div class="f11 p7">充值2000元赠送100元</div>
                  </div>
                </div>
                <div
                  class="f11 cur bd color-0"
                  style="text-align: right;margin-top:55px"
                  @click="goRecharge()"
                >更多优惠 ></div>
              </div>
            </div>
          </div>
        </el-col>
        <el-col :span="8">
          <div class="lower-r">
            <div class="title flex">
              <span>专利统计</span>
              <!-- <div class="lower-l-btn cur">
                <i class="el-icon-arrow-up"></i>
                <span>导出数据</span>
              </div>-->
            </div>
            <div class="p20">
              <div class="tab flex" style="width: 320px ;margin:0;">
                <span
                  @click="indexClick(index)"
                  :class="tadIndex==index?'active':''"
                  v-for="(item ,index) in lowerTabs"
                  :key="index"
                >{{item}}</span>
              </div>
              <div class="mt-10 bar" style="width: 100%; height: 220px"></div>
            </div>
            <div class="p-x30">
              <div class="title2">实时数据域总览</div>
              <div class="lower-r-pie flex">
                 <div class="lower-r-pie1" style="width: 220px; height: 190px"></div>
                <div class="lower-r-pie2" style="width: 220px; height: 190px"></div>
              </div>
            </div>
          </div>
        </el-col>
      </el-row>
    </div>
      <!-- hdong -->
    <el-dialog top="20vh" center  :visible.sync="dialogVisible1" title='提示' width="350px">
      <div>您获得年费免费监控：{{num}}条，{{year}}年</div>
      <span slot="footer" class="dialog-footer">
     <el-button @click="dialogVisible1 = false">稍后再去</el-button>
    <el-button type="primary" @click="gomont">去监控</el-button>
  </span>
    </el-dialog>
  </div>
</template>

<script>
import * as echarts from "echarts";
import business from "./common/business";
import monitor from "./common/monitor";
export default {
  components: {
    business,
    monitor
  },
  data() {
    return {
      lowerTabs: ["外观", "实用新型", "发明"],
      tadIndex: 0,
      patentData: [],//柱状
      statdata: {},//统计标签
      annualFeedata: {},//年费分析
      chargedata: [],//充值列表
      patent:[],//饼一
      all:[],//饼二
       dialogVisible1:false,
      num:'1',
      year:'1',
    };
  },
  created() {
    this.statistics();
    this.annualFee();
    this.memberCard();
    this.getStatus()
  },
  mounted() {
    this.getPatent(1);
    this.drawPie(".compare-item1", [{ value: 8 }, { value: 100 }]);
    this.drawPie(".compare-item2", [{ value: 15 }, { value: 100 }]);
    this.drawPie(".compare-item3", [{ value: 30 }, { value: 100 }]);
  },
  methods: {
        // 查看活动是否进行
   getStatus(){
     this.$request.post("/client/activity/getContractStatus").then(res=>{
       if(res.data.code==200&&res.data.data.status==1){
         this.num=res.data.data.num
         this.year=res.data.data.year
         this.dialogVisible1=true
       }
     })
   },
   gomont(){
      this.dialogVisible1=false
     this.$router.push('/monitoring')
   },
    indexClick(index) {
      this.tadIndex = index;
      this.getPatent(index + 1);
    },
    // 专利相关客户统计

    getPatent(index) {
      this.$request
        .get(`/client/index/patentClientStats?service=${index}`)
        .then(res => {
         
          if (res.data.code == 200) {
            this.patentData = res.data.data.histogram;
            this.patent= [
              { value:'', name: "发明" },
              { value:'', name: "实用" },
              { value:'', name: "外观" }
            ]
           
            this.patent[0].value=res.data.data.overview.patent.invent
            this.patent[1].value=res.data.data.overview.patent.practical
            this.patent[2].value=res.data.data.overview.patent.appearance
             this.all=[
              { value:'', name: "专利" },
              { value:'', name: "商标" },
              { value:'', name: "高企" },
              { value:'', name: "财税" }
            ]
            this.all[0].value=res.data.data.overview.all.patent
            this.all[1].value=res.data.data.overview.all.brand
            this.all[2].value=res.data.data.overview.all.soar
            this.all[3].value=res.data.data.overview.all.finance_taxation
            this.bar(this.patentData, ".bar");
            this.drawPie2(".lower-r-pie1",this.patent);
            this.drawPie2(".lower-r-pie2",this.all);
          }
        });
    },
    // 标签统计查询
    statistics() {
      this.$request.get("/client/index/statistics").then(res => {
        if (res.data.code == 200) {
          this.statdata = res.data.data;
        }
      });
    },
    // 年费分析
    annualFee() {
      this.$request.get("/client/index/annualFeeAnalyse").then(res => {
        if (res.data.code == 200) {
          this.annualFeedata = res.data.data;
        }
      });
    },
    getYear() {
      let year = new Date().getFullYear() - 1;
      return year;
    },
    // 获取充值列表
    memberCard() {
      this.$request.post("/client/member_card/listData").then(res => {
        if (res.data.code == 200) {
          this.chargedata = res.data.data.slice(0, 3);
        }
      });
    },
    // 去充值
    goRecharge() {
      this.$router.push({ path: "/recharge", query: { type: "recharge" } });
    },
    drawPie(classname, piedata) {
      let myChart = echarts.init(document.querySelector(classname));
      myChart.setOption({
        color: ["#eec804", "#2962ff"],
        graphic: {
          type: "text",
          left: "center",
          top: "center",
          z: 2,
          zlevel: 100,
          style: { text: this.getYear(), fill: "#000", width: 45, height: 45 }
        },
        series: [
          {
            type: "pie",
            radius: ["60%", "90%"],
            label: { show: false, position: "center" },
            emphasis: {
              label: { show: false, fontSize: "40", fontWeight: "bold" }
            },
            data: piedata
          }
        ]
      });
    },
    drawPie2(classname, piedata) {
      let myChart = echarts.init(document.querySelector(classname));
      myChart.setOption({
        tooltip: { trigger: "item" },
        center: [0, 0],
        legend: {
          bottom: 0,
          itemWidth: 10,
          itemHight: 10,
          textStyle: {
            fontSize: "10"
          }
        },
        series: [
          {
            name: "访问来源",
            type: "pie",
            radius: "50%",
            center: ["50%", "42%"],
            data:piedata ,
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: "rgba(, 0, 0, 0.5)"
              }
            }
          }
        ]
      });
      window.addEventListener("resize", function() {
        myChart.resize();
      });
    },
    bar(arr, classname) {
      let myChart = echarts.init(document.querySelector(classname));
      myChart.setOption({
        legend: {
          show: true,
          top: 0,
          right: 0
        },
        tooltip: {
          trigger: "axis",
          show: true,
          axisPointer: {
            type: "shadow"
          }
        },
        grid: {
          top: "60",
          left: "20",
          right: "0",
          bottom: "0",
          containLabel: true
        },
        xAxis: {
          type: "category",
          data: ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11"]
        },
        yAxis: {
          type: "value"
        },
        series: [
          {
            name: "高校客户",
            data: arr.type_3,
            type: "bar",
            itemStyle: {
              color: "#1E89F6"
            },
            backgroundStyle: {
              color: "rgba(180, 180, 180, 0.2)"
            }
          },
          {
            name: "企业客户",
            data: arr.type_1,
            type: "bar",
            itemStyle: {
              color: "#F5BB03"
            },
            backgroundStyle: {
              color: "rgba(180, 180, 180, 0.2)"
            }
          },
          {
            name: "个人客户",
            data: arr.type_2,
            type: "bar",
            itemStyle: {
              color: "#52C41A"
            },
            backgroundStyle: {
              color: "rgba(180, 180, 180, 0.2)"
            }
          }
          //  {
          //   name: "其他",
          //   data: arr.type_0,
          //   type: "bar",
          //    itemStyle: {
          //       color: '#717591',
          //   },
          //   backgroundStyle: {
          //     color: "rgba(180, 180, 180, 0.2);"
          //   },

          // }
        ]
      });
      window.addEventListener("resize", function() {
        myChart.resize();
      });
    }
  }
};
</script>

<style>
/* @media screen and (max-width: 1686px) {
    .no {
        display: none
    }
} */
.upper-l {
  height: 307px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.summary img {
  width: 48px;
  margin-right: 32px;
  height: 48px;
}
.summary .item {
  background-color: #fff;
  border-radius: 10px;
  width: 24%;
  justify-content: center;
  height: 104px;
}

.lower-l,
.lower-r {
  height: 615px;
  background-color: #fff;
  border-radius: 10px;
  box-sizing: border-box;
}
.title > span {
  font-size: 20px;
  font-weight: 700;
  color: #222530;
  font-family: Microsoft YaHei, Microsoft YaHei-Bold;
}
.lower-l-btn {
  width: 137px;
  padding: 8px 0;
  border: 1px solid #686868;
  border-radius: 20px;
  text-align: center;
}
.title {
  border-bottom: 1px solid #dedede;
  padding: 24px;
}
.content1,
.content2 {
  padding: 36px;
}
.title2 {
  font-size: 18px;
  font-weight: 700;
  color: #000;
}
.company-item {
  padding: 20px 0;
  border-radius: 10px;
  line-height: 30px;
  border: 1px solid #ff4b64;
  justify-content: space-around;
  font-size: 14px;
}
.company,
.patent,
.compare,
.sum {
  width: 48%;
}
.patent-item {
  padding-bottom: 20px;
  padding-top: 34px;
}
.patent-item .item-img img {
  width: 52px;
  height: 52px;
}
.patent-item .item-img p {
  white-space: nowrap;
  text-align: center;
  font-size: 14px;
  white-space: normal;
}
.item-p p {
  border: 1px solid #1E66F0;
  border-radius: 6px;
  padding: 2px 7px;
  font-size: 12px;
  cursor: pointer;
  white-space: nowrap;
  color: #1E66F0;
}
.item-p p:last-child {
  margin-top: 14px;
  color: #F4AF27;
  border: 1px solid #F4AF27;
}
.compare-item {
  padding-top: 40px;
  align-items: flex-end;
}
.sum-item {
  padding-top: 55px;
}
.rad {
  background-color: #ffe871;
  border-radius: 50%;
  padding: 5px;
}
.rad div {
  width: 80px;
  background-color: #ffda73;
  height: 86px;
  border-radius: 50%;
  text-align: center;
  line-height: 90px;
  color: rgb(182, 128, 91);
  font-size: 20px;
  border: 1px solid #fff;
}
.rad + p {
  margin-top: 10px;
}
.sum-item > div:last-child {
  align-self: flex-end;
  text-align: center;
}
.save {
  text-align: center;
  font-weight: 700;
}
.save-all {
  color: #ff4b64;
}
.last-p {
  margin-top: 42px;
  border: 1px solid;
  border-radius: 6px;
  padding: 2px 7px;
  font-size: 12px;
  cursor: pointer;
  background-color: #ff4b64;
  color: #fff;
  display: inline-block;
}

.lower-r-pie {
  justify-content: space-around;
}
.coupon {
  border: 1px solid #ff293e;
  border-radius: 9px;
  padding-bottom: 10px;
  cursor: pointer;
}
.coupon-top {
  display: flex;
  justify-content: flex-end;
}
.coupon-top span {
  background-color: #ff293e;
  color: #fff;
  border-top-right-radius: 8px;
}
.p7 {
  padding: 0 7px;
}
.tab {
  width: 384px;
  margin: 24px 0 0 24px;
}
.tab span {
  font-size: 14px;
  color: #444444;
  width: 25%;
  padding: 3px 0;
  text-align: center;
  background-color: rgb(234, 241, 255);
  cursor: pointer;
}
.tab span:first-child {
  border-radius: 40px 0 0 40px;
}
.tab span:last-child {
  border-radius: 0 40px 40px 0;
}
.tab .active {
  background-color: #2962ff;
  color: #fff;
}
.cur {
  cursor: pointer;
}
.banner {
  height: 160px;
  opacity: 1;
  background: #c4c4c4;
  background-image: url('../assets/images/banner.jpg');
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
.p20{
  padding: 20px
}
</style>
